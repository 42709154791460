<template>
  <b-container fluid>
    <div id="oferta">
      <p class="serwistytul">WYPOZYCZALNIA: LAWETY, PRZYCZEPKI, AUTA.</p>
      <b-row>
        <b-col id="serwis1" cols="12" md="4">
          <p class="title">Pomoc drogowa:</p>
          <hr />
          <ul class="serwis">
            <li>Holowanie aut</li>
            <li>Transport maszyn rolniczych</li>
            <li>Diagnoza i naprawa na miejscu</li>
            <li>Dowóz paliwa</li>
            <li>Serwis ogumienia</li>
            <li>Awaryjne otwieranie aut</li>
            <li>Trasport Kraj Europa</li>
          </ul>
        </b-col>
        <b-col id="serwis11" cols="12" md="4">
          <p class="title">Mechanika:</p>
          <hr />
          <ul class="serwis">
            <li>Diagnostyka komuterowa</li>
            <li>Ustawianie geometrii kół</li>
            <li>Usługi blacharskie i lakiernicze</li>
            <li>Konserwacja aut</li>
            <li>Naprawa rozruszników i alternatorów</li>
            <li>Naprawy silnika i rozrządu</li>
            <li>Naprawa elementów zawieszenia</li>
            <li>Montaż haków holowniczych</li>
          </ul>
        </b-col>
        <b-col id="serwis12" cols="12" md="4">
          <p class="title">Wypożyczalnia:</p>
          <hr />
          <ul class="serwis">
            <li><b>Wynajem lawet</b></li>
            <li><b>Wynajem przyczepek</b></li>
            <li><b>Wynajem aut</b></li>
            <li>Sprzedaż opon - nowych i używanych</li>
            <li>Przygotowanie aut do przeglądów</li>
          </ul>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
export default {};
</script>

<style scoped src="@/assets/css/offer.css">