<template>
  <footer>
    <b-container class="footer">
      <b-row>
        <b-col cols="3">
          <p class="title">Partnerzy:</p>
          <img style="width: 60%" src="@/assets/dg.jpg" />
        </b-col>
        <b-col cols="6">
          <p class="title">Pomoc Drogowa Ostróda:</p>
          <div>
            <ul>
              <li>Partyzantów 10</li>
              <li>14-100 Ostróda</li>
              <li>tel.: 505 582 392</li>
              <li>e-mail: <a href="mailto:dgcar@wp.pl">dgcar@wp.pl</a></li>
            </ul>
          </div>
        </b-col>
        <b-col class="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m11!1m3!1d1180.475235305864!2d19.945867787709943!3d53.719144714774316!2m2!1f0!2f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471d648612c6883d%3A0x44f226d1908efc21!2sPartyzant%C3%B3w+10!5e0!3m2!1spl!2spl!4v1396195367991"
            style="border: 0"
            width="100%"
            height="100%"
          ></iframe>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>
<script>
export default {};
</script>

<style scoped src="@/assets/css/footer.css">